/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Link as CLink,
  Tooltip as CTooltip,
  Flex,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Tag,
  Text,
  useToast,
  useToken,
  VStack,
} from "@chakra-ui/react";
import {
  EventBadge,
  StatusBadge,
} from "@equidefi/portals/components/investments/StatusBadge";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { userFullName } from "@equidefi/shared";
import { Pagination, Paper } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { capitalize } from "lodash";
import moment from "moment";
import React, {useMemo, useState} from "react";
import NumberFormat from "react-number-format";
import { parsePhoneNumber } from "react-phone-number-input";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import InvestmentClient from "../../clients/InvestmentClient";
import SearchBar from "../../components/SearchBar";
import InfoCard from "../../components/card";
import Spinner from "../../components/spinner";
import { NumberFormatter } from "../../constants/format";
import {isAdmin, isOfferingEditor, isOfferingOwner} from "../../helpers";
import {
  useOfferingFull,
  useOfferingInvestments,
  useOfferingSummary,
} from "../../hooks/useOfferings";
import AppHeader from "../../layouts/header";
import { INQUIRY_STATUSES } from "../../constants/inquiries";
import ExportInvestors from "../../components/investments/modals/ExportInvestors";

const WorkflowEventList = [
  "REGISTRATION",
  "PROFILE",
  "SUBSCRIPTION",
  "PAYMENT",
  "ADDRESS",
  "SIGNERS",
  "KYC",
  "AGREEMENT",
  "ACCREDITATION",
  "DILIGENCE",
  "COUNTERSIGN",
  "CLOSING",
];

const PaginationRow = ({ pagination, onPageChange = () => {} }) => {
  return (
    <Flex w="full" justify="space-between" align="center">
      {!pagination ? (
        "..."
      ) : (
        <Text color="gray" mb="0">
          {NumberFormatter.format(pagination.totalItems ?? 0)}{" "}
          {pagination.totalItems === 1 ? "investor" : "investors"} found
          {pagination.totalItems > 0 && (
            <span>
              {" "}
              - Page {NumberFormatter.format(
                pagination.currentPage ?? 0
              )} of {NumberFormatter.format(pagination.totalPages ?? 0)}
            </span>
          )}
        </Text>
      )}
      <Pagination
        size="sm"
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        onGoToPage={onPageChange}
      />
    </Flex>
  );
};

const Investors = () => {
  const { id } = useParams();
  const [green] = useToken("colors", ["green.600"]);

  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const [searchPhrase, setSearchPhrase] = useQueryParam("search", StringParam);
  const [statusFilter, setStatusFilter] = useQueryParam("status", StringParam);
  const [kycFilter, setKycFilter] = useQueryParam("kyc", StringParam);
  const [fundedFilter, setFundedFilter] = useQueryParam("funded", BooleanParam);

  const [capturableFilter, setCapturableFilter] = useQueryParam(
    "capturable",
    BooleanParam
  );
  const [currentFilter, setCurrentFilter] = useQueryParam(
    "current",
    StringParam
  );

  const { data: me } = useCurrentUser();

  const toast = useToast();

  const investmentApi = useApi(InvestmentClient);

  const { data } = useOfferingFull(id);
  const { data: metrics } = useOfferingSummary(id);
  const { offering } = data ?? {};
  const [ showExport, setExportModal ] = useState(false);
  const {
    data: investmentData,
    refetch: getData,
    isFetching,
  } = useOfferingInvestments(id, {
    searchPhrase,
    status: statusFilter,
    current: currentFilter,
    kycStatus: kycFilter,
    capturable: capturableFilter,
    page: page ?? 1,
    funded: fundedFilter,
  });

  const hasOwnerControl = useMemo(
    () =>
      isOfferingOwner(me, offering?.id) ||
      isAdmin(me),
    [me, offering]
  );

  const { investments = [], pagination = {} } = investmentData ?? {};

  const progressBarLength = useMemo(() => {
    return (metrics?.progress || 0) < 5 ? 5 : metrics?.progress;
  }, [metrics?.progress]);

  // Refers a rule defined here:
  // https://github.com/EquiDeFi/platform/pull/671#issuecomment-2274586047
  const displayProgressNumber = useMemo(() => {
    const progress = metrics?.progress || 0;

    if (progress === 0) return 0;
    if (progress === 100) return 100;

    if (progress > 0 && progress < 1) return 1;
    if (progress > 99 && progress < 100) return 99;

    return Math.round(progress);
  }, [metrics?.progress]);

  const rejectInvestor = async (id) => {
    const status = await Swal({
      title: `Reject`,
      text: `Are you sure you want to reject this investor?`,
      buttons: ["Cancel", "Reject"],
      icon: "warning",
      dangerMode: true,
    });

    if (status) {
      await investmentApi.reject(id);
      getData();
      toast({ status: "success", description: "Investor has been deleted" });
    }
  };

  if (isFetching) {
    return <Spinner show />;
  }

  return (
    <>
      <AppHeader id={id} name="Investors" title={offering?.name} />

      <div className="container-fluid">
        {
          <div id="progress" className="progress" style={{ height: 24 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progressBarLength}%` }}
            >
              {displayProgressNumber}%
            </div>
          </div>
        }

        <div id="summary">
          <div className="row g-3 mt-3">
            <InfoCard
              title="Maximum Offering"
              tooltip="The total amount of funds to potential be raised (not including the optional maximum overage)"
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={offering?.total_amount}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Potential Investors"
              tooltip="The number of investors that have signed up for the offering (minus anyone rejected or refunded)"
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.potentials}
              />
            </InfoCard>
            <InfoCard
              title="Funds Indicated"
              tooltip="The total amount of funds investors have submitted on the subscription page of the workflow."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.indicated}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Total Funds Received"
              tooltip="The total amount of funds that have cleared via credit card/ACH or have been sent manually via check or wire transfer."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.received}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
          </div>
          <div className="row g-3">
            <InfoCard
              title="Funds Available for Closing"
              tooltip="The amount of funds awaiting to be added to the next closing tranche."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.available}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Closed funds"
              tooltip="The total amount of funds that have been added to a closing tranche and transfered from escrow to the issuer bank account."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.closed}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Balance Available to Raise"
              tooltip="Maximum offering amount minus the total funds received"
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.remaining}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
          </div>
        </div>

        <PaginationRow pagination={pagination} onPageChange={setPage} />

        <Paper mt={3}>
          <Box p="3" px="5" className="card-header">
            <Flex gap="2" flexDirection="row" alignItems="center">
              <SearchBar
                defaultSearchPhrase={searchPhrase}
                onClear={() => {
                  setPage(1);
                  setSearchPhrase(undefined);
                }}
                onSearch={(phrase) => {
                  setPage(1);
                  setSearchPhrase(phrase);
                }}
                placeholder="Search Investors..."
              />
              <Checkbox
                isChecked={capturableFilter}
                onChange={(e) => setCapturableFilter(e.target.checked)}
              >
                Capturable
              </Checkbox>

              <Checkbox
                isChecked={fundedFilter}
                onChange={(e) => setFundedFilter(e.target.checked)}
              >
                Funded
              </Checkbox>

              <Menu>
                <MenuButton
                  rightIcon={<Icon.ChevronDown size="1em" />}
                  size="sm"
                  as={Button}
                  colorScheme="gray"
                  textTransform="capitalize"
                  minWidth="auto"
                >
                  Status:{" "}
                  {statusFilter ? statusFilter.toLocaleLowerCase() : "Any"}
                </MenuButton>
                <MenuList zIndex="dropdown">
                  <MenuOptionGroup defaultValue={statusFilter}>
                    <MenuItemOption
                      onClick={() => {
                        setPage(1);
                        setStatusFilter(undefined);
                      }}
                      value=""
                    >
                      Any
                    </MenuItemOption>
                    {[
                      "PENDING",
                      "REVIEW",
                      "APPROVED",
                      "COMPLETED",
                      "REJECTED",
                      "CLOSED",
                    ].map((evt) => {
                      return (
                        <MenuItemOption
                          key={evt}
                          onClick={() => {
                            setPage(1);
                            setStatusFilter(evt);
                          }}
                          value={evt}
                          textTransform="capitalize"
                        >
                          {evt.toLowerCase()}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  rightIcon={<Icon.ChevronDown size="1em" />}
                  size="sm"
                  as={Button}
                  colorScheme="gray"
                  textTransform="capitalize"
                  minWidth="auto"
                >
                  Current Step:{" "}
                  {currentFilter ? currentFilter.toLocaleLowerCase() : "Any"}
                </MenuButton>
                <MenuList rootProps={{ maxH: "100px" }} zIndex="dropdown">
                  <MenuOptionGroup defaultValue="">
                    <MenuItemOption
                      onClick={() => {
                        setPage(1);
                        setCurrentFilter(undefined);
                      }}
                      value=""
                    >
                      Any
                    </MenuItemOption>
                    {WorkflowEventList.map((evt) => {
                      return (
                        <MenuItemOption
                          key={evt}
                          onClick={() => {
                            setPage(1);
                            setCurrentFilter(evt);
                          }}
                          value={evt}
                          textTransform="capitalize"
                        >
                          {evt.toLowerCase()}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  rightIcon={<Icon.ChevronDown size="1em" />}
                  size="sm"
                  as={Button}
                  colorScheme="gray"
                  textTransform="capitalize"
                  minWidth="auto"
                >
                  KYC: {kycFilter ? kycFilter.toLocaleLowerCase() : "Any"}
                </MenuButton>
                <MenuList zIndex="dropdown">
                  <MenuOptionGroup defaultValue={kycFilter}>
                    <MenuItemOption
                      onClick={() => {
                        setPage(1);
                        setKycFilter(undefined);
                      }}
                      value=""
                    >
                      Any
                    </MenuItemOption>
                    {INQUIRY_STATUSES.map((evt) => {
                      return (
                        <MenuItemOption
                          key={evt}
                          onClick={() => {
                            setPage(1);
                            setKycFilter(evt);
                          }}
                          value={evt}
                          textTransform="capitalize"
                        >
                          {evt.toLowerCase()}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
              <Button width={270} size="sm" disabled={!hasOwnerControl} onClick={() => setExportModal(true)}>
                <Icon.HardDrive size=".9em" style={{marginRight: '5px'}}/> Export Investors
              </Button>
            </Flex>
          </Box>

          <div className="table-responsive">
            <table className="table table-hover table-nowrap card-table">
              <thead>
                {investments.length ? (
                  <tr>
                    <th></th>
                    <th>Investor</th>
                    <th>Start Date</th>
                    {!`${offering?.stock_type}`.split(" ").includes("Safe") && (
                      <th className="text-center">Subscription</th>
                    )}

                    <th className="text-end">Amount</th>
                    <th className="text-center">Expires</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Current Step</th>
                    {isAdmin(me) && <th className="text-center">Action</th>}
                  </tr>
                ) : (
                  <tr className="noDataExist">
                    <th>No data exist</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {investments.map((item, index) => {
                  const telephone = item.user?.phone
                    ? parsePhoneNumber(item.user.phone)
                    : null;

                  const entityType = item.entity_type || item.user?.entity_type;

                  return (
                    <tr key={index}>
                      <Box as="td" color="gray.400">
                        <CTooltip
                          label={capitalize(entityType)}
                        >
                          {entityType === "ENTITY" ? (
                            <Icon.Briefcase size="1em" />
                          ) : (
                            <Icon.User size="1em" />
                          )}
                        </CTooltip>
                      </Box>
                      <Box as="td" className="align-baseline">
                        <LinkBox as={Stack} gap={0}>
                          <Text as="span">
                            <LinkOverlay
                              as={Link}
                              to={`/offering/${id}/investment/${item.id}/overview`}
                            >
                              {userFullName(item.user)}
                            </LinkOverlay>
                          </Text>
                          <Flex color="gray.400" align="center" gap={2}>
                            <Icon.Mail size=".85em" />
                            <CLink
                              href={`mailto:${item.user.email}`}
                              rel="external noreferrer noopener"
                              target="_blank"
                              textDecor="underline"
                            >
                              {item.user.email}
                            </CLink>
                          </Flex>
                          <Flex color="gray.400" align="center" gap={2}>
                            <Icon.Phone size=".85em" />
                            <Box
                              as={telephone ? CLink : "span"}
                              href={telephone?.getURI()}
                              textDecor={telephone ? "underline" : null}
                            >
                              {telephone
                                ? telephone.formatInternational()
                                : "N/A"}
                            </Box>
                          </Flex>
                        </LinkBox>
                      </Box>
                      <td className="align-baseline">
                        {moment(item.create_date).format("ll")}
                      </td>
                      {!`${offering?.stock_type}`
                        .split(" ")
                        .includes("Safe") && (
                        <td className="text-center align-baseline">
                          {item.approved_quantity &&
                          item?.approved_quantity !== item?.quantity ? (
                            <td>
                              <div>
                                <NumberFormat
                                  value={item.approved_quantity}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={0}
                                />
                              </div>
                              <del className="small text-muted">
                                <NumberFormat
                                  value={item.quantity}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={0}
                                />
                              </del>
                            </td>
                          ) : (
                            <NumberFormat
                              value={item.quantity}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          )}
                        </td>
                      )}

                      <td className="text-end align-baseline">
                        {item.approved_amount &&
                        item?.amount !== item?.approved_amount ? (
                          <>
                            <Flex justify="end" align="center" gap={2}>
                              {item.is_funded && (
                                <CTooltip label="Funded">
                                  <Icon.CheckCircle
                                    aria-hidden="true"
                                    color={green}
                                    size="1em"
                                  />
                                </CTooltip>
                              )}

                              <NumberFormat
                                value={item.approved_amount}
                                displayType="text"
                                thousandSeparator
                                decimalScale={0}
                                prefix="$"
                              />
                            </Flex>
                            <del className="small text-muted">
                              <NumberFormat
                                value={item.amount}
                                displayType="text"
                                thousandSeparator
                                decimalScale={0}
                                prefix="$"
                              />
                            </del>
                          </>
                        ) : (
                          <Flex justify="end" align="center" gap={2}>
                            {item.is_funded && (
                              <CTooltip label="Funded">
                                <Icon.CheckCircle
                                  aria-hidden="true"
                                  color={green}
                                  size="1em"
                                />
                              </CTooltip>
                            )}

                            <NumberFormat
                              value={item.amount}
                              displayType="text"
                              thousandSeparator
                              decimalScale={0}
                              prefix="$"
                            />
                          </Flex>
                        )}
                      </td>
                      <td className="text-center align-baseline vertical-align-middle">
                        {item.capturable.is_capturable && (
                          <VStack gap="0">
                            {!item.capturable.is_expired ? (
                              <Text
                                as="span"
                                mb="0"
                                textStyle="context"
                                color="black"
                              >
                                {moment(item.capturable.expire_date).format(
                                  "ll"
                                )}
                              </Text>
                            ) : (
                              <Badge size="sm" colorScheme="red">
                                Expired
                              </Badge>
                            )}
                          </VStack>
                        )}
                      </td>
                      <td className="text-center align-baseline vertical-align-middle">
                        <StatusBadge as={Tag} size="sm" action={item.status} />
                      </td>
                      <td className="text-center align-baseline vertical-align-middle">
                        {item?.event_history?.[0]?.workflow_action &&
                          item?.next && (
                            <EventBadge
                              as={Badge}
                              isPlain
                              size="sm"
                              action={item?.event_history?.[0]?.workflow_action}
                              event={item?.next}
                            />
                          )}
                      </td>
                      {isAdmin(me) && (
                        <td className="text-center align-baseline">
                          <CTooltip
                            label="Reject Investor"
                            hasArrow
                            placement="auto"
                          >
                            <IconButton
                              size="xs"
                              colorScheme="gray"
                              onClick={() => rejectInvestor(item.id)}
                              aria-label="Reject Investor"
                              icon={<Icon.XCircle color="red" size="1em" />}
                            />
                          </CTooltip>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Paper>

        <PaginationRow pagination={pagination} onPageChange={setPage} />
        <ExportInvestors
          show={showExport}
          offeringId={id}
          onHide={() => setExportModal(false)}
        />
      </div>
    </>
  );
};

export default Investors;

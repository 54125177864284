import
  {
    Badge,
    Box,
    Button,
    ButtonGroup,
    IconButton,
    Tooltip,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
import { capitalize } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import NumberFormat from "react-number-format";
import swal from "sweetalert";

import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import
  {
    usePaymentConfirm,
    useTerminatePayment,
  } from "../../../hooks/useStripe";
import ManualConfirmModal from "./ManualConfirmModal";
import ManualPaymentModal from "./ManualPaymentModal";
import ManualRefundModal from "./ManualRefundModal";

const PAYMENT_BADGE_MAPS = {
  created: { color: "blue", text: "Payment Pending" },
  requires_action: { color: "gray", text: "Requires Action" },
  requires_payment_method: {
    color: "gray",
    text: "Requires Payment Method",
  },
  requires_capture: {
    color: "blue",
    text: "Requires Capture",
  },
  processing: { color: "green", text: "Payment Processing" },
  succeeded: { color: "green", text: "Succeeded" },
  payment_failed: { color: "red", text: "Payment Failed" },
  canceled: { bg: "orange", text: "Canceled" },
  refunded: { color: "orange", text: "Refunded" },
};

function stripePaymentUrl(paymentIntentId, stripeAccountId) {
  const base = new URL("https://dashboard.stripe.com");

  const prefix = stripeAccountId ? `connect/accounts/${stripeAccountId}` : ``;

  return new URL(`${prefix}/payments/${paymentIntentId}`, base);
}

/**
 *
 * @param {object} props
 * @param {string} props.investmentId
 * @param {() => void} props.onUpdatePayments
 * @param {{ data: import("@stripe/stripe-js").PaymentIntentResult, [key: string]: unknown }} props.payment
 * @returns
 */
const PaymentRow = ({
  investmentId,
  payment,
  offering,
  canEdit = false,
  canRefund = false,
  onUpdatePayments = () => {},
}) => {
  const modalRefund = useDisclosure();
  const modalConfirm = useDisclosure();
  const toast = useToast();

  const canCapturePayment = useMemo(
    () => canEdit && payment?.status === "requires_capture",
    [canEdit, payment?.status]
  );

  const canConfirmPayment = useMemo(
    () =>
      canEdit &&
      payment?.category === "MANUAL" &&
      payment?.status === "created",
    [canEdit, payment?.category, payment?.status]
  );

  const capturePayment = usePaymentConfirm();

  const handleCapturePayment = async () => {
    const status = await swal({
      title: "Confirm Payment?",
      text: "Are you sure ready to capture this payment?",
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await capturePayment.mutateAsync({ investmentId, paymentId: payment.id });
      toast({
        status: "success",
        description: "Payment captured successfully!",
      });
      setTimeout(() => {
        onUpdatePayments();
      }, 2000);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: "Payment is not captured successfully!",
      });
    }
  };

  const terminatePayment = useTerminatePayment();

  const handleTerminatePayment = async () => {
    const status = await swal({
      title: "Terminate Payment?",
      text: "Are you sure you want to terminate this payment?",
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;
    try {
      await terminatePayment.mutateAsync({
        investmentId,
        paymentId: payment.id,
      });
      toast({ status: "success", description: "Payment was terminated" });
      setTimeout(() => {
        onUpdatePayments();
      }, 2000);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: "Payment could not be terminated.",
      });
    }
  };

  const badgeConfig = PAYMENT_BADGE_MAPS[payment.status];

  const created = moment(payment?.create_date);
  const capturableBefore = moment(payment?.expiration_date);
  const capturePaymentExpired = capturableBefore < moment();

  return (
    <Box as="tr" key={payment.id}>
      <Box as="td" verticalAlign="baseline">
        {capitalize(payment.category)}
      </Box>
      <Box as="td" verticalAlign="baseline">
        <Tooltip label={created.format("MMM DD, YYYY h:mma ZZ")}>
          <Box as="span" whiteSpace="nowrap">
            {created.format("ll")}
          </Box>
        </Tooltip>
      </Box>
      <Box as="td" verticalAlign="baseline">
        {badgeConfig && (
          <Badge fontSize="0.65em" colorScheme={badgeConfig.color ?? "gray"}>
            {badgeConfig.text}
          </Badge>
        )}
        {payment.status === "refunded" && payment.refund_notes && (
          <Text textStyle="subtext" mb={0}>
            {payment.refund_notes}
          </Text>
        )}
        {payment.status !== "refunded" && payment.notes && (
          <Text textStyle="subtext" mb={0}>
            {payment.notes}
          </Text>
        )}
        {canCapturePayment && (
          <Text textStyle="subtext" mb={0}>
            {capturePaymentExpired
              ? "Expired"
              : `Capturable before ${capturableBefore.format("ll")}`}
          </Text>
        )}
      </Box>
      <Box as="td" textAlign="end" verticalAlign="baseline">
        <NumberFormat
          value={payment.amount}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={2}
          thousandSeparator
          prefix="$"
        />
      </Box>
      <Box as="td" verticalAlign="baseline">
        <ButtonGroup>
          <>
            {payment.status === "succeeded" && canRefund && (
              <Tooltip label="Refund" hasArrow>
                <IconButton
                  icon={<Icon.CornerUpLeft size="1em" />}
                  colorScheme="gray"
                  size="xs"
                  aria-label="Refund Payment"
                  onClick={modalRefund.onOpen}
                />
              </Tooltip>
            )}
            {canConfirmPayment && (
              <Tooltip label="Confirm" hasArrow>
                <IconButton
                  icon={<Icon.Shield size="1em" />}
                  colorScheme="gray"
                  size="xs"
                  aria-label="Confirm Payment"
                  onClick={modalConfirm.onOpen}
                />
              </Tooltip>
            )}
          </>

          {canCapturePayment && !capturePaymentExpired && (
            <>
              <Tooltip label="Capture Funds" hasArrow>
                <IconButton
                  icon={<Icon.Shield size="1em" />}
                  colorScheme="gray"
                  size="xs"
                  aria-label="Capture Funds"
                  onClick={handleCapturePayment}
                />
              </Tooltip>
              <Tooltip label="Terminate Payment" hasArrow>
                <IconButton
                  icon={<Icon.Trash size="1em" />}
                  colorScheme="red"
                  size="xs"
                  aria-label="Terminate Payment"
                  onClick={handleTerminatePayment}
                />
              </Tooltip>
            </>
          )}
          {payment.payment_intent_id ? (
            <IconButton
              as="a"
              icon={<Icon.ExternalLink size="1em" />}
              colorScheme="gray"
              size="xs"
              aria-label="Go to payment on Stripe"
              href={stripePaymentUrl(
                payment.payment_intent_id,
                offering?.stripe_account_id
              )}
              target="_blank"
              rel="noreferrer noopener"
            >
              Go
            </IconButton>
          ) : null}
        </ButtonGroup>
      </Box>
      {modalRefund.isOpen &&
        createPortal(
          <ManualRefundModal
            isVisible
            investmentId={investmentId}
            paymentId={payment.id}
            onHide={() => modalRefund.onClose()}
            onSubmit={onUpdatePayments}
          />,
          document.body,
          `refund-${payment.id}`
        )}
      {modalConfirm.isOpen &&
        createPortal(
          <ManualConfirmModal
            isVisible
            investmentId={investmentId}
            paymentId={payment.id}
            onHide={() => modalConfirm.onClose()}
            onSubmit={onUpdatePayments}
          />,
          document.body,
          `confirm-${payment.id}`
        )}
    </Box>
  );
};

const InvestmentPayments = ({
  investment,
  offering,
  canEdit = false,
  canRefund = false,
  totalBalance = 0,
  onUpdatePayments = () => {},
}) => {
  const modalPayment = useDisclosure();

  const isInvestmentClosed = useMemo(
    () => !!investment?.offering_traunche_id,
    [investment?.offering_traunche_id]
  );

  const canAddPayment = useMemo(
    () => totalBalance > 0 && !isInvestmentClosed,
    [totalBalance, isInvestmentClosed]
  );

  const payments = useMemo(() => investment?.payments ?? [], [investment]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Payments</h4>

          {offering?.status === "Active" && canEdit && (
            <ButtonGroup size="xs" colorScheme="gray">
              {canAddPayment && (
                <Button onClick={modalPayment.onOpen}>
                  Add Manual Payment
                </Button>
              )}
            </ButtonGroup>
          )}
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Type</th>
              <th>Date</th>
              <th>Status</th>
              <Box as="th" textAlign="end">
                Amount
              </Box>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <PaymentRow
                key={payment.id}
                investmentId={investment?.id}
                payment={payment}
                offering={offering}
                canEdit={canEdit}
                canRefund={canRefund}
                onUpdatePayments={() => onUpdatePayments?.()}
              />
            ))}
          </tbody>
        </table>
      </div>

      {modalPayment.isOpen &&
        createPortal(
          <ManualPaymentModal
            isVisible
            investmentId={investment?.id}
            initialAmount={investment?.amount}
            onHide={modalPayment.onClose}
            onSubmit={() => onUpdatePayments?.()}
          />,
          document.body
        )}
    </>
  );
};

export default InvestmentPayments;

import { SUBMITTED } from "@equidefi/shared/constants/accreditation";
import { ApiClient } from "./ApiClient";

class AccreditationClient extends ApiClient {
  async getDocuments(offeringId, status = SUBMITTED) {
    if (!offeringId) return null;

    const response = await this.get(
      `/v2/accreditation/documents/${offeringId}?status=${status}`
    );
    return response.data;
  }

  async getPresignedUrl(
    investmentId: string,
    vaultField: string,
    fileExtension: string
  ) {
    const response = await this.post(
      `/v2/accreditation/document/${investmentId}`,
      {
        vaultField,
        fileExtension,
      }
    );
    return response.data;
  }

  async submitVerificationReport(
    investmentId: string,
    filename: string,
    extension: string
  ) {
    const response = await this.post(
      `/v2/accreditation/verification/${investmentId}`,
      {
        filename,
        extension,
      }
    );
    return response.data;
  }

  async getReviewToken(offeringId: string) {
    const response = await this.post(`/v2/accreditation/review-token`, {
      offering_id: offeringId,
    });
    return response.data;
  }

  async uploadToken(offeringId: string) {
    const response = await this.post("/v2/accreditation/upload-token", {
      offering_id: offeringId,
    });
    return response.data;
  }

  async getVaultRecord(id: string) {
    const response = await this.get(`/v2/accreditation/vault-record/${id}`);
    return response.data;
  }

  async getDownloadUrls(investmentId: string) {
    const response = await this.get(
      `/v2/accreditation/download-urls/${investmentId}`
    );
    return response.data;
  }

  async getVault(investmentId: string) {
    const response = await this.get(`/v2/accreditation/vault/${investmentId}`);
    return response.data;
  }

  async updateVault(investmentId: string, data: object) {
    const response = await this.post(
      `/v2/accreditation/vault/${investmentId}`,
      data
    );
    return response.data;
  }

  async accept(accreditationId: string, file: string) {
    const response = await this.post(
      `/v2/accreditation/accept/${accreditationId}`,
      {
        file,
      }
    );
    return response.data;
  }

  async reject(accreditationId: string, file: string, reason: string) {
    const response = await this.post(
      `/v2/accreditation/reject/${accreditationId}`,
      { file, reason }
    );

    return response.data;
  }

  async getIsApproved(investmentId: string) {
    const response = await this.get(
      `/v2/accreditation/approved/${investmentId}`
    );

    return response.data;
  }

  async submitPreApproved(investmentId: string) {
    const response = await this.post(
      `/v2/accreditation/preapproved/${investmentId}`
    );

    return response.data;
  }
}

export default AccreditationClient;

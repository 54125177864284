import * as FullStory from "@fullstory/browser";

export type TFullStoryUser = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  entity_type?: string;
};

export const initialize = (orgId?: string) => {
  if (!orgId) return;

  FullStory.init({ orgId });
};

export const identify = (user?: TFullStoryUser) => {
  if (!user) return;
  if (!FullStory.isInitialized()) return;

  FullStory.identify(user.id, {
    displayName: `${user.first_name} ${user.last_name}`,
    entity_type: user?.entity_type,
    email: user.email,
  });

  return FullStory.getCurrentSessionURL();
};

import { useMutation } from "@tanstack/react-query";

import { useApi } from "./useApi";
import AuthClient from "../clients/AuthClient";
import { useAuth } from "../components/AuthProvider";

export interface Login {
  email: string;
  password: string;
}

export interface MFA {
  email: string;
  mfa_code: number;
}

export interface Register {
  first_name: string;
  middle_name?: string;
  last_name: string;
  email: string;
  phone?: string;
  company?: string;
  entity_type: string;
  accept_tos?: Date;
  accept_privacy?: Date;
  accept_sms_email?: Date;
  token?: string;
}

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useLogin = (options = {}) => {
  const authApi = useApi(AuthClient);

  return useMutation(async (data: Login) => authApi.login(data), options);
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useMFACode = (options = {}) => {
  const authApi = useApi(AuthClient);
  const { setToken } = useAuth();

  return useMutation(async (data: MFA) => {
    const res = await authApi.mfa(data);
    setToken(res.token);
    return res;
  }, options);
};

export const useRegister = (
  type: "issuer" | "investor",
  token = "",
  options = {}
) => {
  const authApi = useApi(AuthClient);
  const { setToken } = useAuth();

  return useMutation(async (data: Register) => {
    const res = await authApi.register(data, type, token);
    setToken(res.token);
    return res;
  }, options);
};

export const useWorkflowRegister = (offeringId: string, options = {}) => {
  const authApi = useApi(AuthClient);
  const { setToken } = useAuth();

  return useMutation(async (data: Register) => {
    const res = await authApi.workflowRegister(data, offeringId);
    setToken(res.data.token);
    return res;
  }, options);
};

import { VStack } from "@chakra-ui/react";
import { InvestmentSignatureBox } from "./InvestmentSignatureBox";

const InvestmentSignatureList = ({
  investmentId,
  signingParties,
  onEdit,
  onDelete,
  canEditSigners,
  remindableId = null,
}) => {
  return (
    <VStack as="ul" gap="4" p={0} maxWidth="full">
      {signingParties?.map((party) => (
        <InvestmentSignatureBox
          key={party.id}
          investmentId={investmentId}
          signingParty={party}
          remindableId={remindableId}
          onEdit={onEdit}
          onDelete={onDelete}
          canEditSigners={canEditSigners}
        />
      ))}
    </VStack>
  );
};

export default InvestmentSignatureList;

const TOKEN_KEY = "user:token";
import jwtDecode from "jwt-decode";
import moment from "moment";

class UserToken {
  static token() {
    return localStorage.getItem(TOKEN_KEY);
  }

  static valid(token?: string) {
    const tokenMod = token ?? UserToken.token();

    if (!tokenMod) return false;

    let decoded: any;

    try {
      // Decode the token. Will fail if token is invalid
      decoded = jwtDecode(tokenMod);
    } catch (e) {
      return false;
    }

    const expirationDate = moment(decoded.exp * 1000);
    const isExpired = expirationDate.isAfter(moment());

    return !!isExpired;
  }

  static setToken(value: string) {
    localStorage.setItem(TOKEN_KEY, value);
  }

  static clear() {
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default UserToken;

import React from "react";

import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import InvestmentAgreementCard from "./InvestmentAgreementCard";

const InvestmentSignatures = ({
  investment,
  investmentId,
  isCountersignable = false,
}) => {
  const { data } = useInvestmentSignatures(investmentId);

  const investmentAgreement = data?.investment_agreement;
  const signingParties = data?.signing_parties ?? [];
  const remindableId = data?.remindable?.id;

  return (
    <InvestmentAgreementCard
      key={investmentAgreement?.id}
      isCountersignable={isCountersignable}
      summary={data?.summary}
      investmentId={investmentId}
      agreement={investmentAgreement}
      signingParties={signingParties}
      remindableId={remindableId}
    />
  );
};

export default InvestmentSignatures;
